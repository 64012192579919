// import { Link } from "gatsby"
// import PropTypes from "prop-types"
import React from "react"

const BulletPoint = ({ title, subTitle, year, description  }) => (

  <article className="timeline__item">
    <h5 className="title title--h5 timeline__title">
      {title}
    </h5>
    <span className="timeline__period">{`${subTitle} ${year}`}</span>
    <p className="timeline__description">
      {description}
    </p>
  </article>

)



export default BulletPoint
