// import { Link } from "gatsby"
// import PropTypes from "prop-types"
import React from "react"

const SkillPoint = ({ title, percentage = "100%"  }) => (

  <div className="progress">
      <div
        className="progress-bar"
        style={{width: percentage}}
        role="progressbar"
        aria-valuenow="100"
        aria-valuemin="0"
        aria-valuemax="100"
      >
          <div className="progress-text">
              <span>{title}</span>
              {/*<span>80%</span>*/}
          </div>
      </div>
      <div className="progress-text" style={{color: "#FFFFFF"}}>
          <span>{title}</span>
      </div>
  </div>

)



export default SkillPoint
