import React from "react"
// import { Link } from "gatsby"

import Layout from "../../components/layout/layout.js"
// import Image from "../components/image"
// import SEO from "../components/seo"
import BulletPoint from "../../components/shared/bullet-point.js"
import SkillPoint from "../../components/shared/skill-point"

const KluPwa = () => (

  <Layout>
    {/* <SEO title="Home" /> */}

    <div className="pb-0 pb-sm-2">
      <h1 className="title title--h1 text-center">KLU PWA App</h1>
      <div
        className="gallery-grid__image-wrap col-md-12"
        style={{ paddingBottom: "10px" }}
      >
        <img
          className="gallery-grid__image cover lazyload"
          src={"../assets/img/image_02.jpg"}
          alt=""
        />
      </div>
      <p>
        KLU was founded by two friends who kept getting approached with the same questions "What should I get him for his birthday?, "What should I get to this dinner I'm invited to?", "What should I get her for Valentine's Day?" And "Which one would they like more?". Every year, like clock work, at every occasion, their friends would rely on them for the best recommendation.
        So one day, realizing it's a common question (struggle that most of us have) they decided to do something about it, and help others find the perfect gift. So they embarked on a journey to find the best way to match people to the best gift possible!
      </p>
      <p>
        I was part of this project to design the PWA.
      </p>

      <h4>High level goals</h4>
      <ol>
        <li>Let People Build their Profile.</li>
        <li>Make games to get Users likes/dislikes.</li>
        <li>Let Users invite their Friends & family.</li>
      </ol>
    </div>

    {/*// <!-- Experience -->*/}

    <div className="pb-0">
      <div className="row">
        <div className="col-12 col-lg-12">
          <h2 className="title title--h3">
            {/*<img*/}
            {/*  className="title-icon"*/}
            {/*  src="../assets/icons/icon-experience.svg"*/}
            {/*  alt=""*/}
            {/*/>*/}
            My Role
          </h2>
          <p>
            I worked on the Designed and UX part of the app and collaborated with designers
            and Tech team, Created Wireframe Prototypes and High Fidelity
            Prototypes for client testing. Worked with Front-end team and built
            some UI screens and widgets in Flutter.
          </p>

          <h2 className="title title--h3">
            {/*<img*/}
            {/*  className="title-icon"*/}
            {/*  src="../assets/icons/icon-experience.svg"*/}
            {/*  alt=""*/}
            {/*/>*/}
            :(
          </h2>
          <p>
            The app was later shutdown, but it was a good experience working on this startup project
          </p>


          <div
            className=" col-md-12"
            style={{ paddingBottom: "10px", backgroundColor: "#eee", padding: "20px 0px" }}
          >

            {[...Array(11)].map((x, i) =>
              <img
                key={i}
                className="lazyload col-md-4"
                data-zoom
                src={`../assets/img/klu-screens/${i+1}.png`}
                alt=""
              />
            )}

          </div>


          <div
            className="gallery-grid__image-wrap col-md-12"
            style={{ paddingBottom: "10px" }}
          >
            {/*<img*/}
            {/*  className="gallery-grid__image cover lazyload"*/}
            {/*  src={"../assets/img/image_01.jpg"}*/}
            {/*  alt=""*/}
            {/*/>*/}
          </div>

          <div className="timeline">
            {/*// <!-- Item -->*/}

            {/*<BulletPoint*/}
            {/*  title={"UX/UI & Front-end Developer"}*/}
            {/*  subTitle={"Group Rhinos"}*/}
            {/*  year={"(2018 — Present)"}*/}
            {/*  description={*/}
            {/*    "Working as UX/UI Designer. Hold stakeholder meetings and discuss sketched/inspiration ideas. Do user research and create User epics and user stories. Create User flows and wireframes. Create Prototypes and have it tested with client and stakeholders. Create UI components in Flutter(Dart) or ReactJS."*/}
            {/*  }*/}
            {/*/>*/}
          </div>



        </div>
      </div>
    </div>

    {/* <p>Welcome to your new Gatsby site.</p>
    <p>Now go build something great.</p>
    <div style={{ maxWidth: `300px`, marginBottom: `1.45rem` }}>
      <Image />
    </div>
    <Link to="/page-2/">Go to page 2</Link> */}
  </Layout>
)

export default KluPwa
