/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
// import { useStaticQuery, graphql } from "gatsby"
// import { Helmet } from "gatsby-plugin-react-helmet";
import { Helmet } from "react-helmet"
import "../../assets/styles/style.css"
import "../../assets/styles/custom.css";
import "../../assets/demo/style-demo.css";

import Sidebar from "../sidebar/sidebar"



// import Header from "../header"
import "./layout.css"

const Layout = ({ children }) => {
  // const data = useStaticQuery(graphql`
  //   query SiteTitleQuery {
  //     site {
  //       siteMetadata {
  //         title
  //       }
  //     }
  //   }
  // `)

  return (
    <>
      <Helmet>
        {/* <!-- Styles --> */}
        {/* <link rel="stylesheet" type="text/css" href="../assets/styles/style.css" />
        <link rel="stylesheet" type="text/css" href="assets/demo/style-demo.css" /> */}
        {/*<script src="assets/js/jquery-3.4.1.min.js"></script>*/}
        {/*<script src="assets/js/plugins.js"></script>*/}
        {/*<script src="assets/js/common.js"></script>*/}
        {/*<script src="assets/demo/plugins-demo.js"></script>*/}
        <link href="https://fonts.googleapis.com/css?family=Archivo:400,400i,500,500i,600,600i,700,700i|Merriweather:400,900&display=swap" rel="stylesheet" />
      </Helmet>

      {/* <Header siteTitle={data.site.siteMetadata.title} /> */}
      {/* <div
        style={{
          margin: `0 auto`,
          maxWidth: 960,
          padding: `0px 1.0875rem 1.45rem`,
          paddingTop: 0,
        }}
      >

        <main>{children}</main>
        <footer>
          © {new Date().getFullYear()}, Built with
          {` `}
          <a href="https://www.gatsbyjs.org">Gatsby</a>
        </footer>
      </div> */}

      <div className="bg-triangles">
        <main className="main">
          <div className="container gutter-top">
            <div className="row sticky-parent">
              <Sidebar />

              <div className="col-12 col-md-12 col-xl-9">
                <div className="box pb-0">
                  {/*<div className="circle-menu">*/}
                  {/*  <div className="hamburger">*/}
                  {/*    <div className="line"></div>*/}
                  {/*    <div className="line"></div>*/}
                  {/*    <div className="line"></div>*/}
                  {/*  </div>*/}
                  {/*</div>*/}
                  {/*<div className="inner-menu js-menu">*/}
                  {/*  <ul className="nav">*/}
                  {/*    <li className="nav__item">*/}
                  {/*      <a className="active" href="about.html">*/}
                  {/*        About*/}
                  {/*      </a>*/}
                  {/*    </li>*/}
                  {/*    <li className="nav__item">*/}
                  {/*      <a href="resume.html">Resume</a>*/}
                  {/*    </li>*/}
                  {/*    <li className="nav__item">*/}
                  {/*      <a href="portfolio.html">Portfolio</a>*/}
                  {/*    </li>*/}
                  {/*    <li className="nav__item">*/}
                  {/*      <a href="blog.html">Blog</a>*/}
                  {/*    </li>*/}
                  {/*    <li className="nav__item">*/}
                  {/*      <a href="contact.html">Contact</a>*/}
                  {/*    </li>*/}
                  {/*  </ul>*/}
                  {/*</div>*/}

                  <main>{children}</main>

                  {/*// What i am doing*/}

                  {/*<div className="box-inner pb-0">*/}
                  {/*  <h2 className="title title--h3">What I'm Doing</h2>*/}
                  {/*  <div className="row">*/}
                  {/*    <div className="col-12 col-lg-6">*/}
                  {/*      <div className="case-item box box__second">*/}
                  {/*        <img*/}
                  {/*          className="case-item__icon"*/}
                  {/*          src="assets/icons/icon-design.svg"*/}
                  {/*          alt=""*/}
                  {/*        />*/}
                  {/*        <div>*/}
                  {/*          <h3 className="title title--h5">Web Design</h3>*/}
                  {/*          <p className="case-item__caption">*/}
                  {/*            The most modern and high-quality design made at a*/}
                  {/*            professional level.*/}
                  {/*          </p>*/}
                  {/*        </div>*/}
                  {/*      </div>*/}
                  {/*    </div>*/}

                  {/*    <div className="col-12 col-lg-6">*/}
                  {/*      <div className="case-item box box__second">*/}
                  {/*        <img*/}
                  {/*          className="case-item__icon"*/}
                  {/*          src="assets/icons/icon-dev.svg"*/}
                  {/*          alt=""*/}
                  {/*        />*/}
                  {/*        <div>*/}
                  {/*          <h3 className="title title--h5">Web Development</h3>*/}
                  {/*          <p className="case-item__caption">*/}
                  {/*            High-quality and professional development of sites*/}
                  {/*            at the professional level.*/}
                  {/*          </p>*/}
                  {/*        </div>*/}
                  {/*      </div>*/}
                  {/*    </div>*/}

                  {/*    <div className="col-12 col-lg-6">*/}
                  {/*      <div className="case-item box box__second">*/}
                  {/*        <img*/}
                  {/*          className="case-item__icon"*/}
                  {/*          src="assets/icons/icon-app.svg"*/}
                  {/*          alt=""*/}
                  {/*        />*/}
                  {/*        <div>*/}
                  {/*          <h3 className="title title--h5">Mobile Apps</h3>*/}
                  {/*          <p className="case-item__caption">*/}
                  {/*            Professional development of applications for iOS*/}
                  {/*            and Android.*/}
                  {/*          </p>*/}
                  {/*        </div>*/}
                  {/*      </div>*/}
                  {/*    </div>*/}

                  {/*    <div className="col-12 col-lg-6">*/}
                  {/*      <div className="case-item box box__second">*/}
                  {/*        <img*/}
                  {/*          className="case-item__icon"*/}
                  {/*          src="assets/icons/icon-photo.svg"*/}
                  {/*          alt=""*/}
                  {/*        />*/}
                  {/*        <div>*/}
                  {/*          <h3 className="title title--h5">Photography</h3>*/}
                  {/*          <p className="case-item__caption">*/}
                  {/*            I make high-quality photos of any category at a*/}
                  {/*            professional level.*/}
                  {/*          </p>*/}
                  {/*        </div>*/}
                  {/*      </div>*/}
                  {/*    </div>*/}
                  {/*  </div>*/}
                  {/*</div>*/}

                  {/*// Testimonials*/}
                  {/*<div className="box-inner box-inner--white">*/}
                  {/*  <h2 className="title title--h3">Testimonials</h2>*/}

                  {/*  <div className="swiper-container js-carousel-review">*/}
                  {/*    <div className="swiper-wrapper">*/}
                  {/*      <div className="swiper-slide review-item">*/}
                  {/*        <svg*/}
                  {/*          className="avatar avatar--80"*/}
                  {/*          viewBox="0 0 84 84"*/}
                  {/*        >*/}
                  {/*          <g className="avatar__hexagon">*/}
                  {/*            <image*/}
                  {/*              href="assets/img/avatar-2.jpg"*/}
                  {/*              height="100%"*/}
                  {/*              width="100%"*/}
                  {/*            />*/}
                  {/*          </g>*/}
                  {/*        </svg>*/}
                  {/*        <h4 className="title title--h5">Daniel Lewis</h4>*/}
                  {/*        <p className="review-item__caption">*/}
                  {/*          Felecia was hired to create a corporate identity. We*/}
                  {/*          were very pleased with the work done.*/}
                  {/*        </p>*/}
                  {/*      </div>*/}

                  {/*      <div className="swiper-slide review-item">*/}
                  {/*        <svg*/}
                  {/*          className="avatar avatar--80"*/}
                  {/*          viewBox="0 0 84 84"*/}
                  {/*        >*/}
                  {/*          <g className="avatar__hexagon">*/}
                  {/*            <image*/}
                  {/*              href="assets/img/avatar-3.jpg"*/}
                  {/*              height="100%"*/}
                  {/*              width="100%"*/}
                  {/*            />*/}
                  {/*          </g>*/}
                  {/*        </svg>*/}
                  {/*        <h4 className="title title--h5">Jessica Miller</h4>*/}
                  {/*        <p className="review-item__caption">*/}
                  {/*          thanks to the skill of Felecia, we have a design*/}
                  {/*          that we can be proud of.*/}
                  {/*        </p>*/}
                  {/*      </div>*/}

                  {/*      <div className="swiper-slide review-item">*/}
                  {/*        <svg*/}
                  {/*          className="avatar avatar--80"*/}
                  {/*          viewBox="0 0 84 84"*/}
                  {/*        >*/}
                  {/*          <g className="avatar__hexagon">*/}
                  {/*            <image*/}
                  {/*              href="assets/img/avatar-4.jpg"*/}
                  {/*              height="100%"*/}
                  {/*              width="100%"*/}
                  {/*            />*/}
                  {/*          </g>*/}
                  {/*        </svg>*/}
                  {/*        <h4 className="title title--h5">Tanya Ruiz</h4>*/}
                  {/*        <p className="review-item__caption">*/}
                  {/*          Felecia was hired to create a corporate identity. We*/}
                  {/*          were very pleased with the work done.*/}
                  {/*        </p>*/}
                  {/*      </div>*/}

                  {/*      <div className="swiper-slide review-item">*/}
                  {/*        <svg*/}
                  {/*          className="avatar avatar--80"*/}
                  {/*          viewBox="0 0 84 84"*/}
                  {/*        >*/}
                  {/*          <g className="avatar__hexagon">*/}
                  {/*            <image*/}
                  {/*              href="assets/img/avatar-5.jpg"*/}
                  {/*              height="100%"*/}
                  {/*              width="100%"*/}
                  {/*            />*/}
                  {/*          </g>*/}
                  {/*        </svg>*/}
                  {/*        <h4 className="title title--h5">Thomas Castro</h4>*/}
                  {/*        <p className="review-item__caption">*/}
                  {/*          thanks to the skill of Felecia, we have a design*/}
                  {/*          that we can be proud of.*/}
                  {/*        </p>*/}
                  {/*      </div>*/}
                  {/*    </div>*/}

                  {/*    <div className="swiper-pagination"></div>*/}
                  {/*  </div>*/}
                  {/*</div>*/}

                  {/*// Client*/}

                  {/*<div className="box-inner box-inner--rounded">*/}
                  {/*  <h2 className="title title--h3">Clients</h2>*/}

                  {/*  <div className="swiper-container js-carousel-clients">*/}
                  {/*    <div className="swiper-wrapper">*/}
                  {/*      <div className="swiper-slide">*/}
                  {/*        <a href="#">*/}
                  {/*          <img src="assets/img/logo-partner.svg" alt="Logo" />*/}
                  {/*        </a>*/}
                  {/*      </div>*/}

                  {/*      <div className="swiper-slide">*/}
                  {/*        <a href="#">*/}
                  {/*          <img src="assets/img/logo-partner.svg" alt="Logo" />*/}
                  {/*        </a>*/}
                  {/*      </div>*/}

                  {/*      <div className="swiper-slide">*/}
                  {/*        <a href="#">*/}
                  {/*          <img src="assets/img/logo-partner.svg" alt="Logo" />*/}
                  {/*        </a>*/}
                  {/*      </div>*/}

                  {/*      <div className="swiper-slide">*/}
                  {/*        <a href="#">*/}
                  {/*          <img src="assets/img/logo-partner.svg" alt="Logo" />*/}
                  {/*        </a>*/}
                  {/*      </div>*/}

                  {/*      <div className="swiper-slide">*/}
                  {/*        <a href="#">*/}
                  {/*          <img src="assets/img/logo-partner.svg" alt="Logo" />*/}
                  {/*        </a>*/}
                  {/*      </div>*/}

                  {/*      <div className="swiper-slide">*/}
                  {/*        <a href="#">*/}
                  {/*          <img src="assets/img/logo-partner.svg" alt="Logo" />*/}
                  {/*        </a>*/}
                  {/*      </div>*/}
                  {/*    </div>*/}

                  {/*    <div className="swiper-pagination"></div>*/}
                  {/*  </div>*/}
                  {/*</div>*/}



                </div>
                <footer className="footer">© 2019 Farooq Iqbal</footer>
              </div>
            </div>
          </div>
        </main>

        <div className="back-to-top"></div>

        <svg className="svg-defs">
          <clipPath id="avatar-box">
            <path d="M1.85379 38.4859C2.9221 18.6653 18.6653 2.92275 38.4858 1.85453 56.0986.905299 77.2792 0 94 0c16.721 0 37.901.905299 55.514 1.85453 19.821 1.06822 35.564 16.81077 36.632 36.63137C187.095 56.0922 188 77.267 188 94c0 16.733-.905 37.908-1.854 55.514-1.068 19.821-16.811 35.563-36.632 36.631C131.901 187.095 110.721 188 94 188c-16.7208 0-37.9014-.905-55.5142-1.855-19.8205-1.068-35.5637-16.81-36.63201-36.631C.904831 131.908 0 110.733 0 94c0-16.733.904831-37.9078 1.85379-55.5141z" />
          </clipPath>
          <clipPath id="avatar-hexagon">
            <path d="M0 27.2891c0-4.6662 2.4889-8.976 6.52491-11.2986L31.308 1.72845c3.98-2.290382 8.8697-2.305446 12.8637-.03963l25.234 14.31558C73.4807 18.3162 76 22.6478 76 27.3426V56.684c0 4.6805-2.5041 9.0013-6.5597 11.3186L44.4317 82.2915c-3.9869 2.278-8.8765 2.278-12.8634 0L6.55974 68.0026C2.50414 65.6853 0 61.3645 0 56.684V27.2891z" />
          </clipPath>
        </svg>

        {/*<div className="btnSlideNav slideOpen"></div>*/}
        {/*<div className="btnSlideNav slideClose"></div>*/}

        {/*<ul className="slideNav">*/}
        {/*  <li className="slideNav__item">*/}
        {/*    <h4 className="title title--5">More pages</h4>*/}
        {/*  </li>*/}
        {/*  <li className="slideNav__item">*/}
        {/*    <a href="background-2.html">1. Background turquoise</a>*/}
        {/*  </li>*/}
        {/*  <li className="slideNav__item">*/}
        {/*    <a href="background-3.html">2. Background green</a>*/}
        {/*  </li>*/}
        {/*  <li className="slideNav__item">*/}
        {/*    <a href="menu_alternative/about.html">3. Alternative menu</a>*/}
        {/*  </li>*/}
        {/*</ul>*/}

        {/*<div className="overlay-slideNav"></div>*/}

      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
