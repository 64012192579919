import React from "react"
import { Link } from "gatsby"
import * as CV from "../../assets/cv/resume.pdf";

const Sidebar = () => (
  <aside className="col-12 col-md-12 col-xl-3">
    <div className="sidebar box pb-0 sticky-column" style={{position: "sticky", top: "20px"}}>

      {/*<svg className="avatar avatar--180" viewBox="0 0 188 188">*/}
      {/*  <g className="avatar__box">*/}
      {/*    <image href="assets/img/avatar-1.jpg" height="100%" width="100%" />*/}
      {/*  </g>*/}
      {/*</svg>*/}

      <div className="text-center">
        <h3 className="title title--h3 sidebar__user-name">
          <span className="weight--500">Farooq</span> Iqbal
        </h3>
        <div className="badge badge--gray">
          UX/UI Designer <br /> <br /> Front-end Developer
        </div>

        <div className="nav-links">
          <Link to={"/about"} className="social__link">
            About
          </Link>

          <Link to={"/"} className="social__link">
            Work
          </Link>
        </div>

        {/*<div className="social">*/}
        {/*  <a className="social__link" href="https://www.facebook.com/"><i className="font-icon icon-facebook"></i></a>*/}
        {/*  <a className="social__link" href="https://www.behance.com/"><i className="font-icon icon-twitter"></i></a>*/}
        {/*  <a className="social__link" href="https://www.linkedin.com/"><i className="font-icon icon-linkedin2"></i></a>*/}
        {/*</div>*/}
      </div>

      <div className="sidebar__info box-inner box-inner--rounded">
        <ul className="contacts-block" style={{margin: 0}}>
          {/*<li className="contacts-block__item" data-toggle="tooltip" data-placement="top" title="Birthday">*/}
          {/*  <i className="font-icon icon-calendar"></i>July 29, 1994*/}
          {/*          </li>*/}
          <li
            className="contacts-block__item"
            data-toggle="tooltip"
            data-placement="top"
            title="Address"
          >
            <i className="font-icon icon-location"></i>Dubai, UAE
          </li>
          <li
            className="contacts-block__item"
            data-toggle="tooltip"
            data-placement="top"
            title="E-mail"
          >
            <a href="mailto:farooqqaisrani@outlook.com">
              <i className="font-icon icon-envelope"></i>
              farooqqaisrani@outlook.com
            </a>
          </li>
          <li
            className="contacts-block__item"
            data-toggle="tooltip"
            data-placement="top"
            title="Phone"
          >
            <i className="font-icon icon-phone"></i>+971 56 9893464
          </li>
        </ul>

        <a className="btn btn--blue-gradient" href={CV} download={"Farooq Iqbal Resume"}>
          <i className="font-icon icon-download"></i> Download CV
        </a>
      </div>
    </div>
  </aside>
)

export default Sidebar
